import '../styles/promo.css';

import { Link, graphql } from 'gatsby';

import Hero from '../components/shared/hero-business';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import meta from '../constants/meta-descriptions';
import styled from 'styled-components';

const PromoPage = ({ data }) => {
	const homePromotions = sliceArray(data.homePromotions.edges);

	return (
		<>
			<Layout activeTab={'/promocii/'}>
				<SEO
					title={meta.promo.title}
					description={meta.promo.metaDescription}
					image
				/>
				<Hero
					heroImage={data.heroImage.childImageSharp.fluid.src}
					imageClass="promo-all-hero"
					title={
						<h1 className="text-white mt-sm-3 mt-md-5 px-sm-5">
							Открий неустоимите предложения <br /> на NetSurf тук!
							<br /> Без малък шрифт и скрити клаузи!
						</h1>
					}
				/>

				<div className="container text-center my-5">
					<div className="mt-5">
						{homePromotions.map((arr, index) => (
							<PromoDeck
								key={index}
								arr={arr}
								order={arr.length - 1 > index && index % 2 !== 0}
							/>
						))}
					</div>
				</div>
			</Layout>
		</>
	);
};

const PromoDeck = ({ arr, order }) => {
	return (
		<>
			<div className="row d-flex p-0 m-0">
				<div
					className={
						'col-12 col-xl-4 p-0 m-0 ' + (order ? 'order-0' : 'order-1')
					}
				>
					<div className="row p-0 m-0">
						<div className="col-12 col-xl-12 p-0 m-0">
							{arr[1] != null && (
								<PromoCard
									{...arr[1].node}
									slug={arr[1].node.slug}
									isBig={false}
								/>
							)}
						</div>
						<div className="col-12 col-xl-12 p-0 m-0">
							{arr[2] != null && (
								<PromoCard
									{...arr[2].node}
									slug={arr[2].node.slug}
									isBig={false}
								/>
							)}
						</div>
					</div>
				</div>
				<div
					className={
						'col-12 col-xl-8 p-0 m-0' + (order ? 'order-1' : 'order-0')
					}
				>
					{arr[0] != null && <PromoCard {...arr[0].node} isBig={true} />}
				</div>
			</div>
		</>
	);
};

export const PromoCard = ({ title, slug, acf, featured_image_url, isBig }) => {
	const CardWrapper = styled.div`
		background: #ffffff;
		box-shadow: 0px 0px 30px rgba(51, 1, 109, 0.1);
		border-radius: 8px;
		width: 100%;
		padding: 0;
		min-height: 15rem;
		overflow: hidden;

		a {
			&:hover {
				text-decoration: none;
			}
		}

		.promo-card-title {
			margin-bottom: 5px;
		}

		@media screen and (min-width: 1200px) {
			height: ${isBig ? '36.5rem' : ''};
		}
	`;

	const subtitle = (acf.subtitle || '').slice(0, 200) + '...';

	return (
		<>
			<div className="p-1">
				<CardWrapper isBig={isBig}>
					<Link to={`/promocii/${slug}`}>
						<img
							src={featured_image_url}
							className="img-thumbnail promo-card"
							alt={title}
						/>
						<div className="text-left p-2">
							<h3 className="promo-card-title">{title}</h3>
							<p className="m-0">{subtitle}</p>
						</div>
					</Link>
				</CardWrapper>
			</div>
		</>
	);
};

const sliceArray = (arr) => {
	let newArray = [];
	let i = 0;
	let newArrayIndex = 0;

	while (i < arr.length) {
		if (i + 1 > arr.length - 1 || i + 2 > arr.length - 1 || i + 3 > arr.length - 1) {
			newArray[newArrayIndex] = arr.slice(i, arr.length);
			break;
		} else {
			newArray[newArrayIndex++] = arr.slice(i, i + 3);
			i += 3;
		}
	}

	return newArray;
};

export const query = graphql`
	query PromoQuery {
		homePromotions: allWordpressWpPromotions(sort: { fields: date, order: DESC }) {
			edges {
				node {
					id
					slug
					title
					featured_image_url
					acf {
						subtitle
					}
				}
			}
		}
		heroImage: file(relativePath: { eq: "promo/promo-header.png" }) {
			childImageSharp {
				fluid(maxHeight: 800) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;

export default PromoPage;

